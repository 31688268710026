@font-face {
  font-family: "Outfit-Regular";
  src: url("/assets/fonts/Outfit/Outfit-Light.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Outfit-Regular";
  src: url("/assets/fonts/Outfit/Outfit-Light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Outfit-Regular";
  src: url("/assets/fonts/Outfit/Outfit-Regular.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Outfit-Regular";
  src: url("/assets/fonts/Outfit/Outfit-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Outfit-Regular";
  src: url("/assets/fonts/Outfit/Outfit-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Outfit-Regular";
  src: url("/assets/fonts/Outfit/Outfit-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Outfit-Regular";
  src: url("/assets/fonts/Outfit/Outfit-SemiBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Outfit-Regular";
  src: url("/assets/fonts/Outfit/Outfit-SemiBold.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}