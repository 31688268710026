:root {
  /* Text Colors */
  --textPrimary: #201a20;
  --textPrimaryOnBrand: #ffffff;
  --textSecondary: #423842;
  --textSecondaryHover: #2e232e;
  --textSecondaryOnBrand: #a78aa5;
  --textTertiary: #544854;
  --textTertiaryHover: #423842;
  --textTertiaryOnBrand: #a78aa5;
  --textQuaternary: #847184;
  --textQuaternaryOnBrand: #957292;
  --textWhite: #ffffff;
  --textDisabled: #847184;
  --textPlaceholder: #847184;
  --textPlaceholderSubtle: #ebe8eb;
  --textBrandPrimary: #21081f;
  --textBrandSecondary: #41103d;
  --textBrandTertiary: #430c3f;
  --textErrorPrimary: #d92d20;
  --textWarningPrimary: #dc6803;
  --textSuccessPrimary: #079455;

  /* Border Colors */
  --borderPrimary: #e0dee0;
  --borderSecondary: #f0edf0;
  --borderTertiary: #f5f2f5;
  --borderDisabled: #ebe8eb;
  --borderDisabledSubtle: #f0edf0;
  --borderBrand: #957292;
  --borderBrandSolid: #430c3f;
  --borderBrandSolidAlt: #430c3f;
  --borderError: #fda29b;
  --borderErrorSolid: #d92d20;

  /* Foreground Colors */
  --foregroundPrimary: #201a20;
  --foregroundSecondary: #423842;
  --foregroundSecondaryHover: #2e232e;
  --foregroundTertiary: #544854;
  --foregroundTertiaryHover: #423842;
  --foregroundQuaternary: #e0dee0;
  --foregroundQuaternaryHover: #544854;
  --foregroundQuinary: #9e9e9e;
  --foregroundQuinaryHover: #847184;
  --foregroundSenary: #ebe8eb;
  --foregroundWhite: #ffffff;
  --foregroundDisabled: #e0dee0;
  --foregroundDisabledSubtle: #ebe8eb;
  --foregroundBrandPrimary: #430c3f;
  --foregroundBrandPrimaryAlt: #430c3f;
  --foregroundBrandSecondary: #72436e;
  --foregroundErrorPrimary: #d92d20;
  --foregroundErrorSecondary: #f04438;
  --foregroundWarningPrimary: #dc6803;
  --foregroundWarningSecondary: #f79009;
  --foregroundSuccessPrimary: #079455;
  --foregroundSuccessSecondary: #17b26a;

  /* Background Colors */
  --backgroundPrimary: #ffffff;
  --backgroundPrimaryAlt: #ffffff;
  --backgroundPrimaryHover: #f0edf0;
  --backgroundPrimarySolid: #141114;
  --backgroundSecondary: #f7f5f7;
  --backgroundSecondaryAlt: #f7f5f7;
  --backgroundSecondaryHover: #f5f2f5;
  --backgroundSecondarySubtle: #faf7fa;
  --backgroundSecondarySolid: #544854;
  --backgroundTertiary: #f5f2f5;
  --backgroundQuaternary: #f0edf0;
  --backgroundActive: #f7f5f7;
  --backgroundDisabled: #f3f4f6;
  --backgroundDisabledSubtle: #f5f2f5;
  --backgroundOverlay: #141114;
  --backgroundBrandPrimary: #ede8ed;
  --backgroundBrandPrimaryAlt: #ede8ed;
  --backgroundBrandSecondary: #cab9c9;
  --backgroundBrandSolid: #430c3f;
  --backgroundBrandSolidHover: #41103d;
  --backgroundBrandSection: #310c2e;
  --backgroundBrandSectionSubtle: #41103d;
  --backgroundBrandCheckBoxSelected: #962A8C;

  // Gradiant
  --brandGradient: linear-gradient(to bottom, #9F1E94, #8D3C87, #310C2E);
  --brandSecondaryGradient: linear-gradient(to bottom, #EAC338, #FCAC49, #A46E40);
  --brandAIGradient: linear-gradient(to bottom, #42307D, #45deg, #7F56D9);
  --selectionGradient: linear-gradient(to bottom, #AB57A4, #873681, #A21997);
  --iconsGradient: linear-gradient(to bottom, #AB57A4, #873681, #A21996);
  --downloadPopupBackground: linear-gradient(123.19deg, #FFF4FE 0.62%, #FFEDD7 99.38%);


  --utilityGray50: #f0edf0;
  --utilityGray100: #f5f2f5;
  --utilityGray200: #f0edf0;
  --utilityGray300: #ebe8eb;
  --utilityGray400: #e0dee0;
  --utilityGray500: #847184;
  --utilityGray600: #544854;
  --utilityGray700: #423842;
  --utilityGray800: #2e232e;
  --utilityGray900: #201a20;
  --color-gray-100: #F9FAFB;
  --color-black-transparent: #0000001a;

  --utilityBrand50: #ede8ed;
  --utilityBrand100: #cab9c9;
  --utilityBrand200: #a78aa5;
  --utilityBrand300: #957292;
  --utilityBrand400: #845b80;
  --utilityBrand500: #72436e;
  --utilityBrand600: #430c3f;
  --utilityBrand700: #41103d;
  --utilityBrand800: #310c2e;
  --utilityBrand900: #21081f;
  --Component-colors-Utility-Brand-utility-brand-600: #4F144A;
  --Component-colors-Utility-Brand-utility-brand-500: #72436E;

  --utilityError50: #fef3f2;
  --utilityError100: #fee4e2;
  --utilityError200: #fecdca;
  --utilityError300: #fda29b;
  --utilityError400: #f97066;
  --utilityError500: #f04438;
  --utilityError600: #d92d20;
  --utilityError700: #b42318;

  --utilityWarning50: #fffaeb;
  --utilityWarning100: #fef0c7;
  --utilityWarning200: #fedf89;
  --utilityWarning300: #fec84b;
  --utilityWarning400: #fdb022;
  --utilityWarning500: #f79009;
  --utilityWarning600: #dc6803;
  --utilityWarning700: #b54708;

  --utilitySuccess50: #ecfdf3;
  --utilitySuccess100: #dcfae6;
  --utilitySuccess200: #abefc6;
  --utilitySuccess300: #75e0a7;
  --utilitySuccess400: #47cd89;
  --utilitySuccess500: #17b26a;
  --utilitySuccess600: #079455;
  --utilitySuccess700: #067647;

  --utilityGrayBlue50: #f8f9fc;
  --utilityGrayBlue100: #eaecf5;
  --utilityGrayBlue200: #d5d9eb;
  --utilityGrayBlue300: #b3b8db;
  --utilityGrayBlue400: #717bbc;
  --utilityGrayBlue500: #4e5ba6;
  --utilityGrayBlue600: #3e4784;
  --utilityGrayBlue700: #363f72;

  --utilityBlueLight50: #f0f9ff;
  --utilityBlueLight100: #e0f2fe;
  --utilityBlueLight200: #b9e6fe;
  --utilityBlueLight300: #7cd4fd;
  --utilityBlueLight400: #36bffa;
  --utilityBlueLight500: #0ba5ec;
  --utilityBlueLight600: #0086c9;
  --utilityBlueLight700: #026aa2;
  --utilityGray950: #101828;

  --utilityBlue50: #eff8ff;
  --utilityBlue100: #d1e9ff;
  --utilityBlue200: #b2ddff;
  --utilityBlue300: #84caff;
  --utilityBlue400: #53b1fd;
  --utilityBlue500: #2e90fa;
  --utilityBlue600: #1570ef;
  --utilityBlue700: #175cd3;

  --utilityBlueDark50: #eff4ff;
  --utilityBlueDark100: #d1e0ff;
  --utilityBlueDark200: #b2ccff;
  --utilityBlueDark300: #84adff;
  --utilityBlueDark400: #528bff;
  --utilityBlueDark500: #2970ff;
  --utilityBlueDark600: #155eef;
  --utilityBlueDark700: #004eeb;

  --utilityIndigo50: #eef4ff;
  --utilityIndigo100: #e0eaff;
  --utilityIndigo200: #c7d7fe;
  --utilityIndigo300: #a4bcfd;
  --utilityIndigo400: #8098f9;
  --utilityIndigo500: #6172f3;
  --utilityIndigo600: #444ce7;
  --utilityIndigo700: #3538cd;

  --utilityPurple50: #f4f3ff;
  --utilityPurple100: #ebe9fe;
  --utilityPurple200: #d9d6fe;
  --utilityPurple300: #bdb4fe;
  --utilityPurple400: #9b8afb;
  --utilityPurple500: #7a5af8;
  --utilityPurple600: #6938ef;
  --utilityPurple700: #6938ef;

  --utilityFuchsia50: #fdf4ff;
  --utilityFuchsia100: #fbe8ff;
  --utilityFuchsia200: #f6d0fe;
  --utilityFuchsia300: #eeaafd;
  --utilityFuchsia400: #e478fa;
  --utilityFuchsia500: #d444f1;
  --utilityFuchsia600: #ba24d5;
  --utilityFuchsia700: #9f1ab1;

  --utilityPink50: #fdf2fa;
  --utilityPink100: #fce7f6;
  --utilityPink200: #fcceee;
  --utilityPink300: #faa7e0;
  --utilityPink400: #f670c7;
  --utilityPink500: #ee46bc;
  --utilityPink600: #dd2590;
  --utilityPink700: #c11574;

  --utilityOrangeDark50: #fff4ed;
  --utilityOrangeDark100: #ffe6d5;
  --utilityOrangeDark200: #ffd6ae;
  --utilityOrangeDark300: #ff9c66;
  --utilityOrangeDark400: #ff692e;
  --utilityOrangeDark500: #ff4405;
  --utilityOrangeDark600: #e62e05;
  --utilityOrangeDark700: #bc1b06;

  --utilityOrange50: #fef6ee;
  --utilityOrange100: #fdead7;
  --utilityOrange200: #f9dbaf;
  --utilityOrange300: #f7b27a;
  --utilityOrange400: #f38744;
  --utilityOrange500: #ef6820;
  --utilityOrange600: #e04f16;
  --utilityOrange700: #b93815;


  --utilityBlueGray: #344054;

  --primaryShadow: #41103D3D;
  --secondaryShadow: #10182808;
}
