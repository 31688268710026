@use '@angular/material' as mat;
//@use "./styles/theme.scss";
@import "tailwindcss";
@import url(./styles/_table.scss);
@import url(./styles/_badge-themes.scss);
@import url(./styles/_scrollbar.scss);
@import url(./styles/_variables.scss);
@import url(./styles/_custom-fonts.scss);
@import "./styles/theme.scss";


:root {
    @include mat.progress-spinner-overrides((active-indicator-color: var(--backgroundBrandSolid)));
}

* {
  font-family: "Outfit-Regular", sans-serif;
}

.opus-blur-overlay {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(10px);
  z-index: 999;
}

.toolbar-menu-profile {
  background: var(--backgroundPrimary) !important;
  border-radius: 8px !important;
  width: 240px;
  border: 1px solid var(--borderSecondary);
  margin-top: 10px;
  box-shadow: 0px 20px 24px -4px #10182814 !important;

  hr {
    margin-top: 2px;
    margin-bottom: 2px;
    color: var(--borderSecondary);
    opacity: 1;
  }

  .mat-mdc-menu-content {
    padding: 0px;
  }
}

svg {
  pointer-events: none !important;
}

.user-profile-tab-group {
  .mdc-tab {
    flex-grow: 0 !important;
    font-size: 16px;
    font-weight: 500;
  }

  .mdc-tab--active,
  .mdc-tab:hover {
    color: var(--textBrandSecondary);
  }

  .mat-mdc-tab-label-container {
    border-bottom-color: var(--foregroundQuaternary);
  }

  .mat-mdc-tab .mdc-tab-indicator__content--underline,
  .mdc-tab:hover {
    border-color: var(--textBrandSecondary);
  }
}

@keyframes shine {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

$skeleton-gradient: linear-gradient(
  90deg,
  rgba(224, 224, 224, 1) 0%,
  rgba(240, 240, 240, 1) 50%,
  rgba(224, 224, 224, 1) 100%
);

.skeleton-loader {
    width: 100%;
    height: 100%;
    background: $skeleton-gradient;
    background-size: 200% 100%;
    animation: shine 3s ease-in-out infinite;
}

.modal-container {
    border-radius: 12px;
    background-image: url('/assets/modal/modal-background.svg');
    background-repeat: no-repeat;
}

.progress-spinner-container-append {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }
  .opus-button-shadow {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  
  .opus-button-shadow-2 {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.2) ;
  }