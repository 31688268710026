.no-content-wrapper {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url('/assets/svg/background-pattern-decorative.svg');
    background-repeat: no-repeat;
    background-position: center;

    img {
        width: 152px;
        height: 118px;
    }

    .no-content-heading {
        color: #101828;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    opus-button {
        margin-top: 32px;
    }
}

opus-table-control-menu{
    .options-section{
        .option-item.cdk-drop-list-dragging .option-item:not(.cdk-drag-placeholder) {
            transition: transform 250ms cubic-bezier(0, 0, 0.2, 1); 
        }
    }
}

.cdk-drag-preview {
    border: none;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
}
  
.cdk-drag-placeholder {
    opacity: 0;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}