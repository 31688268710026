::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #dedede;
    background-position: -5px;
    background-repeat: repeat-y;
    background-size: 50px;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #4f144a;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 35px;
}